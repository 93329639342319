
























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { IUserProfileUpdate, ITransaction } from '@/interfaces';
import { dispatchGetUsers, dispatchUpdateUser, dispatchAddUserCredits } from '@/store/admin/actions';
import { readAdminOneUser } from '@/store/admin/getters';
import { readToken } from '@/store/main/getters';

@Component
export default class EditUser extends Vue {
  public valid = true;
  public fullName = '';
  public email = '';
  public isActive = true;
  public isSuperuser = false;
  public setPassword = false;
  public password1 = '';
  public password2 = '';
  public credits = 0;
  public transactions = [] as ITransaction[];
  public addedCredit = 0;
  public note = '';
  public showConfirmAddCredit = false;
  public rules = [v => v.length <= 49 || 'Maximum length reached'];

  public headers = [
    {
      text: 'Transferred',
      sortable: false,
      value: 'transferred',
      align: 'left',
    },
    {
      text: 'Method',
      sortable: true,
      value: 'method',
      align: 'left',
    },
    {
      text: 'Note',
      sortable: false,
      value: 'note',
      align: 'left',
    },
    {
      text: 'Date',
      sortable: true,
      value: 'created',
      align: 'end',
    },
  ];

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.reset();
    await this.getTransactions();
  }

  public async getTransactions() {
    try {
      const token = readToken(this.$store);
      const response = await api.getTransactions(token, this.$router.currentRoute.params.id);
      this.transactions = response.data;
    } catch {
      this.transactions = [];
    }
  }

  public reset() {
    this.setPassword = false;
    this.password1 = '';
    this.password2 = '';
    this.$validator.reset();
    if (this.user) {
      this.fullName = this.user.full_name;
      this.email = this.user.email;
      this.isActive = this.user.is_active;
      this.isSuperuser = this.user.is_superuser;
      this.credits = this.user.credits;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      if (this.setPassword) {
        updatedProfile.password = this.password1;
      }
      await dispatchUpdateUser(this.$store, { id: this.user!.id, user: updatedProfile });
    }
  }

  public async confirmAddCredits() {
    await dispatchAddUserCredits(
      this.$store,
      {
        id: this.$router.currentRoute.params.id,
        credits: this.addedCredit,
        note: this.note
      }
    );
    await this.getTransactions();
    this.showConfirmAddCredit = false;
    this.addedCredit = 0;
    this.note = '';
    this.reset();
  }

  get user() {
    return readAdminOneUser(this.$store)(this.$router.currentRoute.params.id);
  }

  public getFormattedDate(date) {
    return new Date(date).toLocaleDateString();
  }
}
